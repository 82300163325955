@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-themecolor-background;
}

a {
    @apply text-themecolor-primary;
    @apply transition-all;
    @apply ease-in-out;
}

a:hover {
    @apply text-themecolor-hover;
}